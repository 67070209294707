@font-face {
  font-family: $font-apercu-pro;
  src: url('../fonts/apercu-pro-light.woff2') format('woff2'),
      url('../fonts/apercu-pro-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-apercu-pro;
  src: url('../fonts/apercu-pro-medium.woff2') format('woff2'),
      url('../fonts/apercu-pro-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}