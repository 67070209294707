$color-black: #000;
$color-dark-gray: #191919;
$color-light-gray: #999999;
$color-mid-gray: #888888;
$color-white: #FFF;

$font-apercu-pro: 'Apercu Pro';

$ease-in-out: cubic-bezier(0.77, 0, 0.175, 1);

$z-indexes: (
  'preloader',
  'navigation',
  'transition',
  'content',
  'canvas',
);
