%description-10 {
  font-size: 1rem;

  @include media('<phone') {
    font-size: 2rem;
  }
}

%description-14 {
  font-size: 1.4rem;
  line-height: 1.4;

  @include media('<phone') {
    font-size: 2.4rem;
  }
}

%description-16 {
  font-size: 1.6rem;
  line-height: 1.4;

  @include media('<phone') {
    font-size: 2.6rem;
  }
}

%description-24 {
  font-size: 2.4rem;
  line-height: 1.2;

  @include media('<phone') {
    font-size: 2.6rem;
    line-height: 1.4;
  }
}

%description-25 {
  font-size: 2.5rem;
  line-height: 1.2;

  @include media('<phone') {
    font-size: 4rem;
    line-height: 1.3;
  }
}