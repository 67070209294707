.home {
    width: 144rem;
    margin: 0 auto;
    opacity: 0;

    @include media('<phone') {
        width: 100%;
    }
}

.home__wrapper{
    padding: 24rem 10rem 16.9rem;
    will-change: transform;

    @include media('<phone') {
        padding: 25.6rem 6rem 16rem;
    }

    img {
        opacity: 1;
    }
}

.home__intro {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 14rem;

    @include media('<phone') {
        flex-direction: column;
        align-items: baseline;
        margin-bottom: 12rem;
    }
}

.home__intro__text {
    @extend %title-46;

    width: 70.2rem;
    margin-right: 16.2rem;

    @include media('<phone') {
        width: 100%;
        margin: 0 0 6rem;
        line-height: 1.3;
    }
}

.home__intro__tag {
    @extend %description-16;

    width: 37.7rem;
    padding-bottom: 0.8rem;
    opacity: 0;

    @include media('<phone') {
        width: 100%;
    }
}

.home__projects {
    display: grid;
    grid-column-gap: 5.4rem;
    grid-template-columns: repeat(12, 5.39rem);

    @include media('<phone') {
        display: block;
    }
}

.home__project {
    margin-bottom: 14rem;

    &--big {
       grid-column: 1/13;
    }

    &--medium {
        grid-column: 7/13;
    }

    &--small {
        grid-column: 3/7;
    }

    &:nth-child(5) {
        grid-column: 3/9;
    }

    &:nth-child(6) {
        grid-column: 9/13;
    }

    @include media('<phone') {
        margin-bottom: 12rem;
    }
}

.home__project__wrapper{
}

.home__project__link__box {
    display: inline-block;
    position: relative;

    @include media('<phone') {
        display: block;
    }
}

.home__project__link__box__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 105%;
    height: 100%;
    background-color: $color-white;
    transform-origin: 100% 0%;
    transform: scale3d(1, 0, 1);
    z-index: 1;
    pointer-events: none;
}

.home__project__media {
    position: relative;
    overflow: hidden;
    pointer-events: none;

    &--big {
        width: 102.4rem;
        height: 54rem;
    }

    &--medium {
        width: 59.3rem;
        height: 44rem;
    }

    &--small {
        width: 37.7rem;
        height: 28rem;
    }

    @include media('<phone') {
        width: 100%;
        height: 40rem;
    }
}

.home__project__media__image {
    @extend %cover;


    filter: brightness(1);
    object-fit: cover;

    pointer-events: none;

/*
    .home__project__link__box:hover & {
        transform: scale3d(2, 2, 1) !important;
    }
    */
}

.home__project__link {
    @extend %title-24-project;

    position: relative;
    display: flex;
    padding-top: 3rem;
    pointer-events: none;

    /*
    .home__project:nth-child(6) & {
        width: 24rem;
    }

    .home__project:last-child & {
        width: 27rem;
    }

    @include media('<phone') {
       .home__project:nth-child(6) &,
       .home__project:last-child & {
            width: 35rem;
        }
    }
    */
}

.home__project__link__text {
    line-height: 1.2;
    pointer-events: none;
}

.home__project__link__icon {
    position: relative;
    padding-left: 1rem;
    margin-top: -0.1rem;
    overflow: hidden;
    pointer-events: none;

    @include media('<phone') {
        margin-top: -0.3rem;
    }

    &:before,
    svg {
        transition: all 0.6s cubic-bezier(.535, 0, 0, 1);
    }

    &:before {
        content: "";
        position: absolute;
        width: 1.85rem;
        height: 0.1rem;
        background-color: currentColor;
        border-radius: 0.1rem;
        top: 50%;
        margin-top: -0.25rem;
    }

    svg {
        width: 1.85rem;
        height: 1.6rem;
        transform: translate3d(-200%, 0, 0);

        @include media('<phone') {
            width: 2.314rem;
            height: 2rem;
        }
    }

    .home__project:nth-child(6) & {
        position: absolute;
        bottom: -0.4rem;
        left: 14rem;

         @include media('<phone') {
            left: 19rem;
         }
    }

    .home__project:last-child & {
        position: absolute;
        bottom: -0.4rem;
        left: 10.5rem;

        @include media('<phone') {
            left: 14rem;
        }
    }

    .home__project__link__box:hover & {
        &:before {
            transform: translate3d(150%, 0, 0);
        }

        svg {
            transform: translate3d(0, 0, 0);
            transition-delay: 0.25s;
        }
    }
}


.home__footer{

}

.home__misc__link {
    @extend %title-32;

    display: flex;
}

.home__misc__link__icon {
    position: relative;
    overflow: hidden;
    padding-left: 1rem;

    &:before,
    svg {
        transition: all 0.6s cubic-bezier(.535, 0, 0, 1);
    }

    &:before {
        content: "";
        position: absolute;
        width: 1.85rem;
        height: 0.2rem;
        background-color: currentColor;
        border-radius: 0.2rem;
        top: 50%;
        margin-top: -0.25rem;
    }

    svg {
        width: 2.85rem;
        height: 2rem;
        margin-left: -0.5rem;
        transform: translate3d(-200%, 0, 0);
    }

    .home__misc__link:hover & {
        &:before {
            transform: translate3d(200%, 0, 0);
        }

        svg {
            transform: translate3d(0, 0, 0);
            transition-delay: 0.25s;
        }
    }
}

.home__footer__wrapper {
    display: flex;
    margin: 14rem 0;

    @include media('<phone') {
        flex-direction: column;
        margin: 12rem 0;
    }
}

.home__footer__text{
    @extend %title-24-project;

    font-weight: 500;
}

.home__footer__social__list{
    @extend %title-24-project;

    margin-left: 5rem;

    @include media('<phone') {
        margin: 4rem 0 0;
    }
}

.home__footer__social_link{
    @extend %link--hidden;

    margin-right: 5rem;

    @include media('<phone') {
        margin-right: 6rem;
    }
}

.home__top__button {
    @extend %link--top;

    position: relative;
    width: 6rem;
    height: 6rem;
    text-align: center;
    left: 50%;
    transform: translate(-50%);

    .top__circle__after {
        stroke: $color-light-gray;
    }

    @include media('<phone') {
        width: 10rem;
        height: 10rem;
    }

    svg {
        @extend %cover;
    }
}