.navigation {
  @extend %cover;

  pointer-events: none;
  position: fixed;
  z-index: z('navigation');
}

.navigation__logo {
    @extend %title-24-logo;

    position: absolute;
    top: 8rem;
    left: 10rem;
    pointer-events: auto;
    opacity: 0;
    z-index: 1;

    @include media('<phone') {
        top: 10rem;
        left: 6rem;
    }
}

.navigation__menu {
    position: absolute;
    top: 6rem;
    right: 8rem;
    pointer-events: auto;
    z-index: 1;
    transition: all .5s cubic-bezier(.535, 0, 0, 1);
    pointer-events: auto;
    margin: 0;
    padding: 2rem;

    @include media('<phone') {
        top: 9.5rem;
        right: 4.3rem;
    }
}

.navigation__menu__wrapper {
    position: relative;
    width: 2.2rem;
    height: 1.4rem;
    pointer-events: none;

    @include media('<phone') {
        width: 3.7rem;
        height: 2.8rem;
    }
}

.navigation__menu__span {
    position: absolute;
    display: block;
    /*width: 2.2rem;*/
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: $color-black;
    pointer-events: none;
    transform: scaleX(0);

    &:last-child {
        top: 0.8rem;
    }

    &:focus {
        outline: none;
    }

    @include media('<tablet') {
        height: 0.2rem;
        border-radius: 0.2rem;
    }

    @include media('<phone') {
        height: 2px;
        border-radius: 2px;

        &:last-child {
            top: 1.3rem;
        }
    }

    @media(min-width: 3000px) {
        height: 4px;
        border-radius: 4px;
    }
}

.navigation__close__wrapper {
    position: absolute;
    width: 1.8rem;
    height: 1.8rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include media('<phone') {
        width: 3.4rem;
        height: 3.4rem;
        margin-top: -0.6rem;
        margin-left: 0.6rem;
    }
}

.navigation__close__icon {
    line {
        stroke: $color-white;
        stroke-dasharray: 20;
        stroke-dashoffset: 20;

            /*
        &:first-child {
            stroke-dashoffset: 20;
        }

        &:last-child {
            stroke-dashoffset: -20;
        }
        */
    }
}

.navigation__overlay {
    @extend %cover;

    display: none;
    // display: flex;
    justify-content: center;
    align-items: center;
    color: $color-light-gray;
    pointer-events: auto;
    transform: translateY(-100%);

    @include media('<phone') {
        padding: 27rem 6rem 0;
        justify-content: flex-start;
        align-items: baseline;
    }
}

.navigation__overlay__wrapper {
    display: flex;
    align-items: flex-end;

    @include media('<phone') {
        flex-direction: column;
        align-items: flex-start;
    }
}

.navigation__list__wrapper {
    width: 64.7rem;

    @include media('<phone') {
        width: 100%;
        margin-bottom: 12rem;
    }
}

.navigation__list__title {
    @extend %title-12;

    margin-bottom: 2rem;
    display: inline-block;

    @include media('<phone') {
        margin-bottom: 4rem;
    }
}


.navigation__list {

}


.navigation__list__item {
    @extend %title-36;
    margin-bottom: 0.8rem;

    @include media('<phone') {
        margin-bottom: 1.7rem;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.navigation__list__link {
    @extend %link--midline;

    transition: color 0.4s ease;

    &:hover,
    &--active {
        color: $color-white;

        &:after {
            @extend %link__midline--visible;
        }
    }
}

.navigation__list__link__span,
.navigation__list__link__span__span {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
}


.navigation__social__wrapper {
    padding-bottom: .2rem;

    @include media('<phone') {
        padding-bottom: 0;
    }
}

.navigation__social__list {
    margin-bottom: 6rem;

    @include media('<phone') {
        margin-bottom: 12rem;
    }
}


.navigation__social__list__item {
    @extend %title-18-social;

    margin-bottom: 0.8rem;

    @include media('<phone') {
        margin-bottom: 1.6rem;
    }
}

.navigation__social__list__link {
    @extend %link--hidden;
    transition: color 0.4s ease;

    &:hover {
        color: $color-white;
    }
}


.navigation__footer {
    @extend %description-10;

    a {
        @extend %link;

        transition: color 0.4s ease;

        &:hover {
            color: $color-white;
        }
    }
}

.navigation__footer__text {

}
