html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.home__top__button svg, .home__project__media__image, .project__button__top svg, .navigation__overlay, .navigation, .transition, .content, body, html {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.navigation__footer {
  font-size: 1rem;
}
@media (max-width: 767px) {
  .navigation__footer {
    font-size: 2rem;
  }
}

.project__stats__label, body {
  font-size: 1.4rem;
  line-height: 1.4;
}
@media (max-width: 767px) {
  .project__stats__label, body {
    font-size: 2.4rem;
  }
}

.home__intro__tag {
  font-size: 1.6rem;
  line-height: 1.4;
}
@media (max-width: 767px) {
  .home__intro__tag {
    font-size: 2.6rem;
  }
}

.project__text__description {
  font-size: 2.4rem;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .project__text__description {
    font-size: 2.6rem;
    line-height: 1.4;
  }
}

.navigation__list__link, .home__footer__social_link, .navigation__social__list__link, .navigation__footer a {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
}

.home__footer__social_link:after, .navigation__social__list__link:after, .navigation__footer a:after {
  background: currentColor;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  transition: transform 0.7s cubic-bezier(0.77, 0, 0.175, 1);
  width: 100%;
}

.home__footer__social_link:hover:after, .navigation__social__list__link:hover:after, .navigation__footer a:after {
  transform: scaleX(1);
  transform-origin: left center;
}

.home__footer__social_link:after, .navigation__social__list__link:after, .navigation__footer a:hover:after {
  transform: scaleX(0);
  transform-origin: right center;
}

.navigation__footer a {
  display: inline-block;
}
.home__footer__social_link, .navigation__social__list__link {
  display: inline-block;
}
/* Custom Line Anims */
.navigation__list__link:after {
  content: "";
  position: absolute;
  background: currentColor;
  left: auto;
  right: -3.6rem;
  top: 50%;
  bottom: auto;
  width: 3.2rem;
  height: 0.2rem;
  margin-top: -0.2rem;
  transform: translate3d(-15%, 0, 0);
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.535, 0, 0, 1);
  z-index: 0;
}

.navigation__list__link--active:after, .navigation__list__link:hover:after {
  opacity: 1;
  width: 2.4rem;
  transform: translate3d(0, 0, 0);
}

.navigation__list__link {
  display: inline-block;
  overflow: visible;
}
/* To Top */
.home__top__button .top__circle__before, .project__button__top .top__circle__before,
.home__top__button .top__circle__after,
.project__button__top .top__circle__after {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
}
.home__top__button .top__circle__before, .project__button__top .top__circle__before {
  stroke-dashoffset: 0;
}
.home__top__button .top__circle__before, .project__button__top .top__circle__before,
.home__top__button .top__circle__after,
.project__button__top .top__circle__after,
.home__top__button .top__before,
.project__button__top .top__before,
.home__top__button .top__after,
.project__button__top .top__after {
  transition: all 0.5s cubic-bezier(0.535, 0, 0, 1);
}
.home__top__button .top__before, .project__button__top .top__before {
  transform: translate3d(0, 0, 0);
  transition-delay: 0.2s;
}
.home__top__button .top__after, .project__button__top .top__after {
  transform: translate3d(0, 150%, 0);
}
.home__top__button:hover .top__circle__before, .project__button__top:hover .top__circle__before {
  stroke-dashoffset: -200;
}
.home__top__button:hover .top__circle__after, .project__button__top:hover .top__circle__after {
  stroke-dashoffset: 0;
}
.home__top__button:hover .top__before, .project__button__top:hover .top__before {
  transform: translate3d(0, -150%, 0);
  transition-delay: 0s;
}
.home__top__button:hover .top__after, .project__button__top:hover .top__after {
  transform: translate3d(0, 0, 0);
  transition-delay: 0.2s;
}

/* Prev/Next Projects */
.project__button__prev .arrow__before,
.project__button__next .arrow__before,
.project__button__prev .arrow__after,
.project__button__next .arrow__after {
  transition: all 0.5s cubic-bezier(0.535, 0, 0, 1);
}
.project__button__prev .arrow__before,
.project__button__next .arrow__before {
  transform: translate(0, 0);
  transition-delay: 0.2s;
}
.project__button__prev .arrow__after,
.project__button__next .arrow__after {
  transform: translateX(-200%);
}
.project__button__prev:hover .arrow__before,
.project__button__next:hover .arrow__before {
  transform: translateX(200%);
  transition-delay: 0s;
}
.project__button__prev:hover .arrow__after,
.project__button__next:hover .arrow__after {
  transform: translateX(0);
  transition-delay: 0.2s;
}

.navigation__list__title {
  font-size: 1.2rem;
  font-weight: 500;
}
@media (max-width: 767px) {
  .navigation__list__title {
    font-size: 2.4rem;
  }
}

.project__stats__label__header {
  font-size: 1.4rem;
  line-height: 1.4;
}
@media (max-width: 767px) {
  .project__stats__label__header {
    font-size: 2.4rem;
  }
}

.navigation__social__list__item {
  font-size: 1.8rem;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .navigation__social__list__item {
    font-size: 2.6rem;
  }
}

.navigation__logo {
  font-size: 2.4rem;
  font-weight: 500;
}
@media (max-width: 767px) {
  .navigation__logo {
    font-size: 3.6rem;
  }
}

.home__footer__social__list, .home__footer__text, .home__project__link {
  font-size: 2.4rem;
}
@media (max-width: 767px) {
  .home__footer__social__list, .home__footer__text, .home__project__link {
    font-size: 3.2rem;
  }
}

.project__text__title {
  font-size: 2.4rem;
  font-weight: 500;
}
@media (max-width: 767px) {
  .project__text__title {
    font-size: 3.6rem;
  }
}

.home__misc__link {
  font-size: 3.2rem;
}
@media (max-width: 767px) {
  .home__misc__link {
    font-size: 3.6rem;
  }
}

.navigation__list__item {
  font-size: 3.6rem;
  line-height: 1.2;
}

.home__intro__text {
  font-size: 4.6rem;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .home__intro__text {
    font-size: 4rem;
  }
}

@font-face {
  font-family: "Apercu Pro";
  src: url("../fonts/apercu-pro-light.woff2") format("woff2"), url("../fonts/apercu-pro-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Apercu Pro";
  src: url("../fonts/apercu-pro-medium.woff2") format("woff2"), url("../fonts/apercu-pro-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
*,
*:after,
*:before {
  box-sizing: border-box;
  user-select: none;
}

html {
  background: #FFF;
  color: #000;
  font-size: 0.5208333333vw;
  line-height: 1;
  overflow: hidden;
  position: fixed;
}
@media (max-width: 767px) {
  html {
    font-size: 1.3888888889vw;
  }
}

body {
  font-family: "Apercu Pro";
  font-weight: 300;
  position: fixed;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

canvas {
  pointer-events: none;
  position: relative;
  z-index: 1;
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
}

img {
  opacity: 0;
  transition: opacity 0.4s ease;
  vertical-align: middle;
}
img.loaded {
  opacity: 1;
}

[data-animation=label] span,
[data-animation=paragraph] span,
[data-animation=title] span {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}

[data-animation=button] {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
[data-animation=button] span {
  display: inline-block;
  transform-origin: center center 0.5em;
  vertical-align: middle;
}

[data-animation=link] {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
[data-animation=link] span {
  display: inline-block;
  transform-origin: center center 0.5em;
  vertical-align: middle;
}

.transition {
  z-index: 3;
}

.navigation {
  pointer-events: none;
  position: fixed;
  z-index: 4;
}

.navigation__logo {
  position: absolute;
  top: 8rem;
  left: 10rem;
  pointer-events: auto;
  opacity: 0;
  z-index: 1;
}
@media (max-width: 767px) {
  .navigation__logo {
    top: 10rem;
    left: 6rem;
  }
}

.navigation__menu {
  position: absolute;
  top: 6rem;
  right: 8rem;
  pointer-events: auto;
  z-index: 1;
  transition: all 0.5s cubic-bezier(0.535, 0, 0, 1);
  pointer-events: auto;
  margin: 0;
  padding: 2rem;
}
@media (max-width: 767px) {
  .navigation__menu {
    top: 9.5rem;
    right: 4.3rem;
  }
}

.navigation__menu__wrapper {
  position: relative;
  width: 2.2rem;
  height: 1.4rem;
  pointer-events: none;
}
@media (max-width: 767px) {
  .navigation__menu__wrapper {
    width: 3.7rem;
    height: 2.8rem;
  }
}

.navigation__menu__span {
  position: absolute;
  display: block;
  /*width: 2.2rem;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: #000;
  pointer-events: none;
  transform: scaleX(0);
}
.navigation__menu__span:last-child {
  top: 0.8rem;
}
.navigation__menu__span:focus {
  outline: none;
}
@media (max-width: 1023px) {
  .navigation__menu__span {
    height: 0.2rem;
    border-radius: 0.2rem;
  }
}
@media (max-width: 767px) {
  .navigation__menu__span {
    height: 2px;
    border-radius: 2px;
  }
  .navigation__menu__span:last-child {
    top: 1.3rem;
  }
}
@media (min-width: 3000px) {
  .navigation__menu__span {
    height: 4px;
    border-radius: 4px;
  }
}

.navigation__close__wrapper {
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .navigation__close__wrapper {
    width: 3.4rem;
    height: 3.4rem;
    margin-top: -0.6rem;
    margin-left: 0.6rem;
  }
}

.navigation__close__icon line {
  stroke: #FFF;
  stroke-dasharray: 20;
  stroke-dashoffset: 20;
  /*
  &:first-child {
      stroke-dashoffset: 20;
  }

  &:last-child {
      stroke-dashoffset: -20;
  }
  */
}

.navigation__overlay {
  display: none;
  justify-content: center;
  align-items: center;
  color: #999999;
  pointer-events: auto;
  transform: translateY(-100%);
}
@media (max-width: 767px) {
  .navigation__overlay {
    padding: 27rem 6rem 0;
    justify-content: flex-start;
    align-items: baseline;
  }
}

.navigation__overlay__wrapper {
  display: flex;
  align-items: flex-end;
}
@media (max-width: 767px) {
  .navigation__overlay__wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}

.navigation__list__wrapper {
  width: 64.7rem;
}
@media (max-width: 767px) {
  .navigation__list__wrapper {
    width: 100%;
    margin-bottom: 12rem;
  }
}

.navigation__list__title {
  margin-bottom: 2rem;
  display: inline-block;
}
@media (max-width: 767px) {
  .navigation__list__title {
    margin-bottom: 4rem;
  }
}

.navigation__list__item {
  margin-bottom: 0.8rem;
}
@media (max-width: 767px) {
  .navigation__list__item {
    margin-bottom: 1.7rem;
  }
}
.navigation__list__item:last-child {
  margin-bottom: 0;
}

.navigation__list__link {
  transition: color 0.4s ease;
}
.navigation__list__link:hover, .navigation__list__link--active {
  color: #FFF;
}
.navigation__list__link__span,
.navigation__list__link__span__span {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}

.navigation__social__wrapper {
  padding-bottom: 0.2rem;
}
@media (max-width: 767px) {
  .navigation__social__wrapper {
    padding-bottom: 0;
  }
}

.navigation__social__list {
  margin-bottom: 6rem;
}
@media (max-width: 767px) {
  .navigation__social__list {
    margin-bottom: 12rem;
  }
}

.navigation__social__list__item {
  margin-bottom: 0.8rem;
}
@media (max-width: 767px) {
  .navigation__social__list__item {
    margin-bottom: 1.6rem;
  }
}

.navigation__social__list__link {
  transition: color 0.4s ease;
}
.navigation__social__list__link:hover {
  color: #FFF;
}

.navigation__footer a {
  transition: color 0.4s ease;
}
.navigation__footer a:hover {
  color: #FFF;
}

html {
  --project--scaleX: 100%;
}

.project {
  width: 144rem;
  margin: 0 auto;
  opacity: 0;
}
@media (max-width: 767px) {
  .project {
    width: 100%;
  }
}

.project__wrapper {
  padding: 21rem 10rem 11.9rem;
  will-change: transform;
}
@media (max-width: 767px) {
  .project__wrapper {
    padding: 26rem 6rem 16rem;
  }
}

.project__text {
  padding: 0 10.7rem;
  margin-bottom: 12.2rem;
}
@media (max-width: 767px) {
  .project__text {
    padding: 0;
    margin-bottom: 8.6rem;
  }
}

.project__text__title {
  margin-bottom: 4.85rem;
}

.project__text__wrapper {
  display: flex;
}
@media (max-width: 767px) {
  .project__text__wrapper {
    display: block;
  }
}

.project__text__description {
  width: 70.1rem;
  margin-right: 16.2rem;
}
@media (max-width: 767px) {
  .project__text__description {
    width: 100%;
    margin-right: 0;
    margin-bottom: 6rem;
  }
}

.project__stats_wrapper {
  display: flex;
  flex-direction: column;
}

.project__stats__item {
  display: inline-block;
}

.project__stats__label__header {
  font-weight: 500;
}

.project__stats__label {
  margin-bottom: 3.2rem;
}
@media (max-width: 767px) {
  .project__stats__label {
    margin-bottom: 4rem;
  }
}

.project__media--spaced {
  margin-bottom: 12rem;
}
.project__media figure {
  position: relative;
  background-color: #999999;
  min-height: 30rem;
}
@media (max-width: 767px) {
  .project__media figure {
    min-height: 10rem;
  }
}
.project__media .spinner {
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 50%;
  left: 50%;
  border: 0.3rem solid #FFF;
  border-top-color: #191919;
  border-radius: 50%;
  margin: -1.5rem 0 0 -1.5rem;
  animation: Rotate 1.2s linear 0s infinite forwards;
}
.project__media .spinner.loaded {
  display: none;
}
.project__media img {
  pointer-events: none;
}

.project__embed {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  background-color: #999999;
}

.project__iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.project__hero__media {
  position: relative;
  overflow: hidden;
}

.project__hero__media__image {
  width: 100%;
}

.project__grid {
  display: flex;
}
.project__grid--3 .project__item__wrapper {
  width: 41.3rem;
}
@media (max-width: 767px) {
  .project__grid--4 {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.project__item__wrapper {
  position: relative;
}
.project__item__wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFF;
  width: 105%;
  height: 105%;
  transform: scaleX(var(--project--scaleX));
  transform-origin: 100% 0%;
  z-index: 0;
}

.project__grid__media {
  position: relative;
  overflow: hidden;
  /* &--3 {
       width: 41.3rem;
   }*/
}
.project__grid__media--spaced {
  margin-right: 5.4rem;
}
.project__grid__media--4 {
  width: 27rem;
}
@media (max-width: 767px) {
  .project__grid__media--4 {
    margin-bottom: 6rem;
    margin-right: 0;
  }
  .project__item__wrapper:nth-child(3) .project__grid__media--4, .project__item__wrapper:last-child .project__grid__media--4 {
    margin-bottom: 0;
  }
}
.project__item__wrapper:last-child .project__grid__media {
  margin-right: 0;
}

.project__grid__media__image {
  width: 100%;
}

.project__video__player {
  position: relative;
  width: 100%;
  background-color: #999999;
  z-index: 2;
}

.project__button__top {
  position: relative;
  width: 6rem;
  height: 6rem;
  text-align: center;
  left: 50%;
  transform: translate(-50%);
}
.project__button__top .top__circle__after {
  stroke: #999999;
}
@media (max-width: 767px) {
  .project__button__top {
    width: 10rem;
    height: 10rem;
  }
}
.project__button__prev,
.project__button__next {
  position: fixed;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  height: 5rem;
  overflow: hidden;
}
.project__button__prev--active .project__button__text span,
.project__button__next--active .project__button__text span {
  transform: translateY(0);
}
.project__button__prev:hover .project__button__text span,
.project__button__next:hover .project__button__text span {
  transform: translateY(0);
}
@media (max-width: 767px) {
  .project__button__prev,
.project__button__next {
    display: none;
    visibility: hidden;
  }
}

.project__button__prev {
  left: 10rem;
  pointer-events: all;
}

.project__button__next {
  right: 10rem;
  pointer-events: all;
}

.project__button__icon {
  display: flex;
  width: 5.1rem;
  padding-left: 1.5rem;
  padding-right: 1.6rem;
  margin-top: -0.3rem;
  pointer-events: none;
  background-color: #FFF;
}
.project__button__prev .project__button__icon {
  transform: scaleX(-1);
}

.project__button__text {
  font-size: 1.6rem;
}

.project__button__text,
.project__button__text span {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  transition: all 0.6s cubic-bezier(0.535, 0, 0, 1);
  pointer-events: none;
}

.project__button__text span {
  transform: translateY(100%);
}

@keyframes Rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.home {
  width: 144rem;
  margin: 0 auto;
  opacity: 0;
}
@media (max-width: 767px) {
  .home {
    width: 100%;
  }
}

.home__wrapper {
  padding: 24rem 10rem 16.9rem;
  will-change: transform;
}
@media (max-width: 767px) {
  .home__wrapper {
    padding: 25.6rem 6rem 16rem;
  }
}
.home__wrapper img {
  opacity: 1;
}

.home__intro {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 14rem;
}
@media (max-width: 767px) {
  .home__intro {
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 12rem;
  }
}

.home__intro__text {
  width: 70.2rem;
  margin-right: 16.2rem;
}
@media (max-width: 767px) {
  .home__intro__text {
    width: 100%;
    margin: 0 0 6rem;
    line-height: 1.3;
  }
}

.home__intro__tag {
  width: 37.7rem;
  padding-bottom: 0.8rem;
  opacity: 0;
}
@media (max-width: 767px) {
  .home__intro__tag {
    width: 100%;
  }
}

.home__projects {
  display: grid;
  grid-column-gap: 5.4rem;
  grid-template-columns: repeat(12, 5.39rem);
}
@media (max-width: 767px) {
  .home__projects {
    display: block;
  }
}

.home__project {
  margin-bottom: 14rem;
}
.home__project--big {
  grid-column: 1/13;
}
.home__project--medium {
  grid-column: 7/13;
}
.home__project--small {
  grid-column: 3/7;
}
.home__project:nth-child(5) {
  grid-column: 3/9;
}
.home__project:nth-child(6) {
  grid-column: 9/13;
}
@media (max-width: 767px) {
  .home__project {
    margin-bottom: 12rem;
  }
}

.home__project__link__box {
  display: inline-block;
  position: relative;
}
@media (max-width: 767px) {
  .home__project__link__box {
    display: block;
  }
}

.home__project__link__box__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 105%;
  height: 100%;
  background-color: #FFF;
  transform-origin: 100% 0%;
  transform: scale3d(1, 0, 1);
  z-index: 1;
  pointer-events: none;
}

.home__project__media {
  position: relative;
  overflow: hidden;
  pointer-events: none;
}
.home__project__media--big {
  width: 102.4rem;
  height: 54rem;
}
.home__project__media--medium {
  width: 59.3rem;
  height: 44rem;
}
.home__project__media--small {
  width: 37.7rem;
  height: 28rem;
}
@media (max-width: 767px) {
  .home__project__media {
    width: 100%;
    height: 40rem;
  }
}

.home__project__media__image {
  filter: brightness(1);
  object-fit: cover;
  pointer-events: none;
  /*
      .home__project__link__box:hover & {
          transform: scale3d(2, 2, 1) !important;
      }
      */
}

.home__project__link {
  position: relative;
  display: flex;
  padding-top: 3rem;
  pointer-events: none;
  /*
  .home__project:nth-child(6) & {
      width: 24rem;
  }

  .home__project:last-child & {
      width: 27rem;
  }

  @include media('<phone') {
     .home__project:nth-child(6) &,
     .home__project:last-child & {
          width: 35rem;
      }
  }
  */
}

.home__project__link__text {
  line-height: 1.2;
  pointer-events: none;
}

.home__project__link__icon {
  position: relative;
  padding-left: 1rem;
  margin-top: -0.1rem;
  overflow: hidden;
  pointer-events: none;
}
@media (max-width: 767px) {
  .home__project__link__icon {
    margin-top: -0.3rem;
  }
}
.home__project__link__icon:before,
.home__project__link__icon svg {
  transition: all 0.6s cubic-bezier(0.535, 0, 0, 1);
}
.home__project__link__icon:before {
  content: "";
  position: absolute;
  width: 1.85rem;
  height: 0.1rem;
  background-color: currentColor;
  border-radius: 0.1rem;
  top: 50%;
  margin-top: -0.25rem;
}
.home__project__link__icon svg {
  width: 1.85rem;
  height: 1.6rem;
  transform: translate3d(-200%, 0, 0);
}
@media (max-width: 767px) {
  .home__project__link__icon svg {
    width: 2.314rem;
    height: 2rem;
  }
}
.home__project:nth-child(6) .home__project__link__icon {
  position: absolute;
  bottom: -0.4rem;
  left: 14rem;
}
@media (max-width: 767px) {
  .home__project:nth-child(6) .home__project__link__icon {
    left: 19rem;
  }
}
.home__project:last-child .home__project__link__icon {
  position: absolute;
  bottom: -0.4rem;
  left: 10.5rem;
}
@media (max-width: 767px) {
  .home__project:last-child .home__project__link__icon {
    left: 14rem;
  }
}
.home__project__link__box:hover .home__project__link__icon:before {
  transform: translate3d(150%, 0, 0);
}
.home__project__link__box:hover .home__project__link__icon svg {
  transform: translate3d(0, 0, 0);
  transition-delay: 0.25s;
}

.home__misc__link {
  display: flex;
}

.home__misc__link__icon {
  position: relative;
  overflow: hidden;
  padding-left: 1rem;
}
.home__misc__link__icon:before,
.home__misc__link__icon svg {
  transition: all 0.6s cubic-bezier(0.535, 0, 0, 1);
}
.home__misc__link__icon:before {
  content: "";
  position: absolute;
  width: 1.85rem;
  height: 0.2rem;
  background-color: currentColor;
  border-radius: 0.2rem;
  top: 50%;
  margin-top: -0.25rem;
}
.home__misc__link__icon svg {
  width: 2.85rem;
  height: 2rem;
  margin-left: -0.5rem;
  transform: translate3d(-200%, 0, 0);
}
.home__misc__link:hover .home__misc__link__icon:before {
  transform: translate3d(200%, 0, 0);
}
.home__misc__link:hover .home__misc__link__icon svg {
  transform: translate3d(0, 0, 0);
  transition-delay: 0.25s;
}

.home__footer__wrapper {
  display: flex;
  margin: 14rem 0;
}
@media (max-width: 767px) {
  .home__footer__wrapper {
    flex-direction: column;
    margin: 12rem 0;
  }
}

.home__footer__text {
  font-weight: 500;
}

.home__footer__social__list {
  margin-left: 5rem;
}
@media (max-width: 767px) {
  .home__footer__social__list {
    margin: 4rem 0 0;
  }
}

.home__footer__social_link {
  margin-right: 5rem;
}
@media (max-width: 767px) {
  .home__footer__social_link {
    margin-right: 6rem;
  }
}

.home__top__button {
  position: relative;
  width: 6rem;
  height: 6rem;
  text-align: center;
  left: 50%;
  transform: translate(-50%);
}
.home__top__button .top__circle__after {
  stroke: #999999;
}
@media (max-width: 767px) {
  .home__top__button {
    width: 10rem;
    height: 10rem;
  }
}