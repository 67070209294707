*,
*:after,
*:before {
  box-sizing: border-box;
  user-select: none;
}

html {
  @extend %cover;

  background: $color-white;
  color: $color-black;
  font-size: calc(100vw / 1920 * 10);
  line-height: 1;
  overflow: hidden;
  position: fixed;

  @include media('<phone') {
    font-size: calc(100vw / 720 * 10);
  }
}

body {
  @extend %cover;
  @extend %description-14;

  font-family: $font-apercu-pro;
  font-weight: 300;
  position: fixed;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

canvas {
  pointer-events: none;
  position: relative;
  z-index: z('canvas');
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
}

img {
  opacity: 0;
  transition: opacity 0.4s ease;
  vertical-align: middle;

  &.loaded {
    opacity: 1;
  }
}

.content {
  @extend %cover;
}

[data-animation="label"],
[data-animation="paragraph"],
[data-animation="title"] {
  span {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
  }
}

[data-animation="button"] {
  display: inline-block;
  position: relative;
  white-space: nowrap;

  span {
    display: inline-block;
    transform-origin: center center 0.5em;
    vertical-align: middle;
  }
}

[data-animation="link"] {
  display: inline-block;
  position: relative;
  white-space: nowrap;

  span {
    display: inline-block;
    transform-origin: center center 0.5em;
    vertical-align: middle;
  }
}
