%title-12 {
    font-size: 1.2rem;
    font-weight: 500;

    @include media('<phone') {
      font-size: 2.4rem;
    }
}

%title-14 {
  font-size: 1.4rem;
  line-height: 1.4;

  @include media('<phone') {
    font-size: 2.4rem;
  }
}

%title-18-project {
  font-size: 1.8rem;
  line-height: 1;

  @include media('<phone') {
    font-size: 3.2rem;
  }
}

%title-18-social {
  font-size: 1.8rem;
  line-height: 1.2;

   @include media('<phone') {
    font-size: 2.6rem;
  }
}

%title-24-logo {
    font-size: 2.4rem;
    font-weight: 500;

     @include media('<phone') {
        font-size: 3.6rem;
    }
}

%title-24-project {
    font-size: 2.4rem;

     @include media('<phone') {
        font-size: 3.2rem;
    }
}

%title-24-project-detail {
    font-size: 2.4rem;
    font-weight: 500;

     @include media('<phone') {
        font-size: 3.6rem;
    }
}

%title-32 {
  font-size: 3.2rem;

  @include media('<phone') {
    font-size: 3.6rem;
  }
}

%title-36 {
  font-size: 3.6rem;
  line-height: 1.2;
}

%title-46 {
  font-size: 4.6rem;
  line-height: 1.2;

  @include media('<phone') {
    font-size: 4rem;
  }
}