%link__wrapper {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
}

%link__line {
  background: currentColor;
  bottom: 0;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  transition: transform 0.7s $ease-in-out;
  width: 100%;
}

%link__line--visible {
  transform: scaleX(1);
  transform-origin: left center;
}

%link__line--hidden {
  transform: scaleX(0);
  transform-origin: right center;
}

%link {
  @extend %link__wrapper;

  display: inline-block;

  &:after {
    @extend %link__line;
    @extend %link__line--visible;
  }

  &:hover {
    &:after {
      @extend %link__line--hidden;
    }
  }
}

%link--hidden {
  @extend %link__wrapper;

  display: inline-block;

  &:after {
    @extend %link__line;
    @extend %link__line--hidden;
  }

  &:hover {
    &:after {
      @extend %link__line--visible;
    }
  }
}

/* Custom Line Anims */
%link__midline {
  content: '';
  position: absolute;
  background: currentColor;
  left: auto;
  right: -3.6rem;
  top: 50%;
  bottom: auto;
  width: 3.2rem;
  height: 0.2rem;
  margin-top: -0.2rem;
  transform: translate3d(-15%, 0, 0);
  opacity: 0;
  transition: all 0.6s cubic-bezier(.535, 0, 0, 1);
  z-index: 0;
}

%link__midline--visible {
  opacity: 1;
  width: 2.4rem;
  transform: translate3d(0, 0, 0);
}


%link--midline {
  @extend %link__wrapper;
  display: inline-block;
  overflow: visible;

  &:after {
    @extend %link__midline;
  }

  &:hover {
    &:after {
      @extend %link__midline--visible;
    }
  }
}

/* To Top */
%link--top {
  .top__circle__before,
  .top__circle__after {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
  }

  .top__circle__before {
    stroke-dashoffset: 0;
  }

  .top__circle__before,
  .top__circle__after,
  .top__before,
  .top__after {
    transition: all 0.5s cubic-bezier(.535, 0, 0, 1);
  }

  .top__before {
    transform: translate3d(0, 0, 0);
    transition-delay: 0.2s;
  }
  .top__after {
    transform: translate3d(0, 150%, 0);
  }

  &:hover {
    .top__circle__before {
      stroke-dashoffset: -200;
    }

     .top__circle__after {
      stroke-dashoffset: 0;
    }

    .top__before {
      transform: translate3d(0, -150%, 0);
      transition-delay: 0s;
    }
    .top__after {
      transform: translate3d(0, 0, 0);
      transition-delay: 0.2s;
    }
  }
}

/* Prev/Next Projects */
%link--next {
  .arrow__before,
  .arrow__after {
    transition: all 0.5s cubic-bezier(.535, 0, 0, 1);
  }

  .arrow__before {
    transform: translate(0, 0);
    transition-delay: 0.2s;
  }
  .arrow__after {
    transform: translateX(-200%);
  }

  &:hover {
    .arrow__before {
      transform: translateX(200%);
      transition-delay: 0s;
    }
    .arrow__after {
      transform: translateX(0);
      transition-delay: 0.2s;
    }
  }
}