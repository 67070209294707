$spaced-margin: 12rem;

html {
    --project--scaleX: 100%;
}

.project{
    width: 144rem;
    margin: 0 auto;
    opacity: 0;

    @include media('<phone') {
        width: 100%;
    }
}

.project__wrapper {
    padding: 21rem 10rem 11.9rem;
    will-change: transform;

     @include media('<phone') {
        padding: 26rem 6rem 16rem;
     }
}

.project__text {
    padding: 0 10.7rem;
    margin-bottom: 12.2rem;

    @include media('<phone') {
        padding: 0;
        margin-bottom: 8.6rem;
    }
}

.project__text__title {
    @extend %title-24-project-detail;

    margin-bottom: 4.85rem;
}

.project__text__wrapper{
    display: flex;

    @include media('<phone') {
        display: block;
    }
}

.project__text__description{
    @extend %description-24;

    width: 70.1rem;
    margin-right: 16.2rem;

    @include media('<phone') {
        width: 100%;
        margin-right: 0;
        margin-bottom: 6rem;
    }
}


.project__stats{

}

.project__stats_wrapper{
    display: flex;
    flex-direction: column;
}

.project__stats__item {
    display: inline-block;
}


.project__stats__label__header {
    @extend %title-14;

    font-weight: 500;
}

.project__stats__label{
    @extend %description-14;

    margin-bottom: 3.2rem;

    @include media('<phone') {
        margin-bottom: 4rem;
    }
}


.project__media {
    &--spaced {
        margin-bottom: $spaced-margin;
    }

    figure {
        position: relative;
        background-color: $color-light-gray;
        min-height: 30rem;

        @include media('<phone') {
            min-height: 10rem;
        }
    }

    .spinner {
        position: absolute;
        width: 3rem;
        height: 3rem;
        top: 50%;
        left: 50%;
        border: .3rem solid $color-white;
        border-top-color: $color-dark-gray;
        border-radius: 50%;
        margin: -1.5rem 0 0 -1.5rem;
        animation: Rotate 1.2s linear 0s infinite forwards;

        &.loaded {
            display: none;
        }
    }

    img {
        pointer-events: none;
    }
}

.project__embed{
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    background-color: $color-light-gray;
}

.project__iframe{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.project__hero{

}

.project__hero__media {
    position: relative;
    overflow: hidden;
}

.project__hero__media__image {
   width: 100%;
}

.project__grid {
    display: flex;

    &--3 .project__item__wrapper {
        width: 41.3rem;
    }

    &--4 {
        @include media('<phone') {
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }
}

.project__item__wrapper {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: $color-white;
        width: 105%;
        height: 105%;
        transform: scaleX(var(--project--scaleX));
        transform-origin: 100% 0%;
        z-index: 0;
    }
}

.project__grid__media {
    position: relative;
    overflow: hidden;

    &--spaced {
        margin-right: 5.4rem;
    }

   /* &--3 {
        width: 41.3rem;
    }*/

    &--4 {
        width: 27rem;

        @include media('<phone') {
            margin-bottom: 6rem;
            margin-right: 0;

            .project__item__wrapper:nth-child(3) &,
            .project__item__wrapper:last-child & {
                margin-bottom: 0;
            }
        }
    }

    .project__item__wrapper:last-child & {
        margin-right: 0;
    }
}

.project__grid__media__image{
    width: 100%;
}

.project__video__player {
    position: relative;
    width: 100%;
    background-color: $color-light-gray;
    z-index: 2;
}

.project__button__top{
    @extend %link--top;

    position: relative;
    width: 6rem;
    height: 6rem;
    text-align: center;
    left: 50%;
    transform: translate(-50%);

    .top__circle__after {
        stroke: $color-light-gray;
    }

    @include media('<phone') {
        width: 10rem;
        height: 10rem;
    }

    svg {
        @extend %cover;
    }
}

.project__button__prev,
.project__button__next {
    @extend %link--next;

    position: fixed;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    height: 5rem;
    overflow: hidden;


    &--active {
        .project__button__text span {
            transform: translateY(0);
        }
    }

    &:hover {
        .project__button__text span {
            transform: translateY(0);
        }
    }

    @include media('<phone') {
        display: none;
        visibility: hidden;
    }
}

.project__button__prev {
    left: 10rem;
    pointer-events: all;
}

.project__button__next {
    right: 10rem;
    pointer-events: all;
}

.project__button__icon {
    display: flex;
    width: 5.1rem;
    padding-left: 1.5rem;
    padding-right: 1.6rem;
    margin-top: -0.3rem;
    pointer-events: none;
    background-color: $color-white;

    .project__button__prev & {
        transform: scaleX(-1);
    }
}

.project__button__text {
    font-size: 1.6rem;
}

.project__button__text,
.project__button__text span {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
    transition: all 0.6s cubic-bezier(.535, 0, 0, 1);
    pointer-events: none;
}

.project__button__text span {
    transform: translateY(100%);
}

@keyframes Rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}